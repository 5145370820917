<template>
    <div class="group">
        <p>{{header}}</p>
        <div class="select--group--container" :class="[orientation]">
            <label class="container" v-for="(value,index) of values" v-bind:key="index">{{value}}
                <input type="radio" :name="'radio'+group" @change="$emit('input',index)" v-bind:value="index" v-model="mutSelectedValue">
                <span class="checkmark"></span>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RegisterSelect",
        props:{
            header: String,
            values: Array,
            orientation:String,
            group: String,
          selectedValue : Number
        },
      data(){
          return {
            mutSelectedValue : 0
          }
      },
      watch:{
        selectedValue:{
          deep:true,
          immediate:true,
          handler(newVal){
            this.mutSelectedValue = newVal
          }
        }
      }
    }
</script>

<style scoped lang="scss">
    /* The container */


    .group{
        p{
            font-family: Roboto, "sans-serif";
            font-size: 12px;
            color: #2C2C2C;
            letter-spacing: 0;
            line-height: 14px;

          @include for-desktop-up{
            font-size: 8px;
            line-height: 9px;
          }
        }
    }


    .select--group--container{
        display: flex;
        margin-top: 9px;

      @include for-desktop-up{
        margin-top: 6px;
      }

        &.vertical{
            flex-direction: column;

            .container{
                margin-top: 27px;

              @include for-desktop-up{
                margin-top: 13px;
              }
            }

            .container:first-child{
                margin-top: 0px;
            }
        }

    }


    .container {
        display: block;
        position: relative;
        padding-left: 27px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-family: Roboto, "sans-serif";
        font-size: 12px;
        color: #2C2C2C;
        letter-spacing: 0;;
        margin-right: 36px;
        line-height:16px;

      @include for-desktop-up{
        font-size: 8px;
        line-height: 12px;
        margin-right: 24px;
        padding-left: 18px;
      }
    }

    /* Hide the browser's default radio button */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: white;
        border-radius: 50%;

        border: 1px solid #979797;


      @include for-desktop-up{
        height: 10px;
        width: 10px;
      }
    }


    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
        background: #6F64F8;
        box-shadow: -1px 1px 2px 0 #6F64F8;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
        width: 15px;
        height: 15px;
        border-radius: 50%;

      @include for-desktop-up{
        height: 10px;
        width: 10px;
      }
    }
</style>

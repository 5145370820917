<template>
    <div class="camera-create--view">
        <div class="form">
            <div class="input--container">
                <register-input header="URL:" size="lg" v-model="uri"></register-input>
            </div>
            <div class="input--container">
                <register-input header="Имя камеры:" size="lg" v-model="cameraName"></register-input>
            </div>
            <div class="slider--container">
                <p>Архив:</p>
                <div class="slider-inner--container">
                    <input class="slider"/>
                    <p>{{daysCount}} дней</p>
                </div>
            </div>
            <div class="radios--container">
                <div class="container">
                    <register-select header="Видео:" :values="['SD 480p','HD 720p','FHD 1080p']" orientation="vertical" group="1" v-model="videoType"></register-select>
                </div>
                <div class="container">
                    <register-select header="Аудио:" :values="['Без звука','Со звуком']" orientation="vertical" group="2" v-model="audioType"></register-select>

                </div>
            </div>
            <div class="buttons--container">
                <button class="btn btn__close" @click="goToUser">ОТМЕНА</button>
                <button class="btn btn__action" @click="createCamera" :class="{'disabled' : !isEnable}">ГОТОВО</button>
            </div>
        </div>
    </div>
</template>

<script>
    import 'bootstrap-slider/dist/css/bootstrap-slider.min.css'
    import RegisterInput from "@/components/input/RegisterInput";
    import RegisterSelect from "@/components/select/RegisterSelect";
    import Slider from "bootstrap-slider";

    export default {
        name: "CreateCameraView",
        components: {RegisterSelect, RegisterInput},
        data(){
            return {
                slider : null,
                audioType : null,
                videoType: null,
                cameraName: '',
                uri: ''
            }
        },
        computed:{
          isEnable(){
            return this.uri.length > 0 && this.cameraName.length > 0 && this.videoType != null && this.audioType != null
          },
            daysCount(){
                if (this.slider == null) return 0
                return this.slider.getValue()
            }
        },
        methods:{
            goToUser(){
                this.$router.push({name : "UserView",params : {id : this.$route.params.id}})
            },

          async createCamera(){
              const res = await this.$store.dispatch("CREATE_CAMERA",{userId: this.$route.params.id,
                cameraData: {
                  name: this.cameraName,
                  uri: this.uri,
                  isAudio: !!this.audioType,
                  videoType: this.videoType,
                  daysCount: this.daysCount
                }})

            if (res === 201){
              this.goToUser();
            }
          }
        },
        mounted(){
            this.slider = new Slider("input.slider", {
                min: 0,
                max: 30,
                value: 0
            });

        }
    }
</script>

<style scoped lang="scss">
    .camera-create--view{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .form{
            background: #FFFFFF;
            box-shadow: 0 2px 6px 0 rgba(0,0,0,0.14);
            border-radius: 6px;
            width: 450px;
            height: 495px;

          @include for-desktop-up{
            width: 300px;
            height: 330px;
            box-shadow: 0 1px 4px 0 rgba(0,0,0,0.14);
            border-radius: 4px;
          }

            .input--container:first-child{
                margin-top: 33px;

              @include for-desktop-up{
                margin-top: 28px;
              }
            }

            .input--container{
                margin-top: 10px;
                margin-left: 38px;

              @include for-desktop-up{
                margin-top: 7px;
                margin-left: 25px;
              }
            }

            .slider--container{
                margin-top: 24px;
                margin-left: 37px;

              @include for-desktop-up{
                margin-top: 16px;
                margin-left: 25px;
              }

                p{
                    margin-bottom: 13px;
                    font-family: Roboto, "sans-serif";
                    font-size: 12px;
                    color: #2C2C2C;
                    letter-spacing: 0;

                  @include for-desktop-up{
                    margin-bottom: 8px;
                    font-size: 8px;
                  }
                }

                .slider-inner--container{
                    position: relative;
                    width: 308px;

                  @include for-desktop-up{
                    width: 265px;

                  }

                    p{
                        position: absolute;
                        top: 0;
                        right: 0;
                        font-family: Roboto, "sans-serif";
                        font-size: 12px;
                        color: #2C2C2C;
                        letter-spacing: 0;
                        text-align: right;

                      @include for-desktop-up{
                        font-size: 10px;

                      }
                    }
                }

            }

            .radios--container{
                margin-top: 31px;
                margin-left: 39px;
                display: flex;

              @include for-desktop-up{
                margin-top: 21px;
                margin-left: 25px;
              }
            }

            .buttons--container{
                display: flex;
                justify-content: flex-end;
                margin-top: 81px;
                margin-right: 30px;

              @include for-desktop-up{
                margin-top: 49px;
                margin-right: 20px;
              }
                .btn{
                    width: 100px;
                    height: 21px;
                    background: #6F64F8;
                    border-radius: 4px;
                    font-family: Roboto, "sans-serif";
                    font-size: 8px;
                    letter-spacing: 0;
                    border: 0;
                    outline: 0;
                    cursor: pointer;

                    @include for-big-desktop-up{
                        width: 150px;
                        height: 32px;
                        border-radius: 6px;
                        font-size: 12px;
                        letter-spacing: 0;
                    }

                    &:hover{
                        filter: brightness(87.5%);
                    }

                    &.not_available{
                        pointer-events: none;
                        opacity: .5;
                    }
                }

                .btn__action{
                    background: #6F64F8;
                    color: #FFFFFF;
                }

                .btn__close{
                    background: #EDEDED;
                    color: #2C2C2C;
                    margin-right: 15px;

                    @include for-big-desktop-up{
                        margin-right: 20px;
                    }
                }
            }
        }
    }


    .disabled{
      pointer-events: none;
      opacity: .5;
    }
</style>
